<template>
  <v-card outlined>
    <v-card-title>
      <v-avatar size="32" rounded color="info" class="mr-3">
        <v-icon color="white">
          {{ icon }}
        </v-icon>
      </v-avatar>
      {{ title }}
    </v-card-title>

    <v-card-text>
      <slot></slot>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-text class="grey lighten-5 text-right pb-2 pt-2">
      <slot name="actions"></slot>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Title"
    },
    icon: {
      type: String,
      default: "mdi-account-details"
    }
  }
};
</script>
