<template>
  <settings-card :title="t('security_options')" icon="mdi-form-textbox-password">
    <v-form ref="form" v-model="formValid" class="mb-10">
      <p class="subtitle-1 mb-0">
        {{ t("security_code") }}
      </p>

      <p class="caption pt-0 mb-0">
        {{ t("security_code_description") }}
      </p>

      <ca-form-row>
        <v-col cols="12" class="pt-0">
          <v-checkbox v-model="useSecurityCode" :label="t('use_security_code_checkbox_label')" />
        </v-col>
      </ca-form-row>

      <ca-form-row v-show="useSecurityCode && !isSecurityCodeSet">
        <v-col cols="6">
          <ca-text-field
            v-model="securityCode"
            :error-messages="errors.securityCode ? errors.securityCode : []"
            type="password"
            :label="t('security_code_field_label')"
          />
        </v-col>

        <v-col cols="6">
          <ca-text-field
            v-model="securityCodeConfirmation"
            :rules="rules.securityCodeConfirmation"
            type="password"
            :label="t('security_code_confirmation_field_label')"
          />
        </v-col>
      </ca-form-row>

      <ca-form-row v-show="!useSecurityCode && isSecurityCodeSet">
        <v-col cols="12">
          <ca-text-field
            v-model="currentSecurityCode"
            :error-messages="errors.currentSecurityCode ? errors.currentSecurityCode : []"
            type="password"
            :label="t('current_security_code_field_label')"
          />
        </v-col>
      </ca-form-row>

      <p v-if="useSecurityCode && !isSecurityCodeSet" class="caption mt-2">
        {{ t("security_code_hint") }}
      </p>

      <p class="subtitle-1 mb-0  mt-5">
        {{ t("password_hacking_prevention_system") }}
      </p>

      <ca-form-row>
        <v-col cols="12" class="pt-0">
          <v-checkbox
            v-model="passProtection"
            :label="t('limit_login_attempts_field_label')"
            :messages="[t('limit_login_attempts_field_hint')]"
          />
        </v-col>
      </ca-form-row>

      <ca-form-row>
        <v-col cols="12" class="pt-10">
          <v-btn elevation="0" color="info" :loading="cleaningAllSessions" @click="cleanAllSessions">
            {{ t("drop_all_sessions") }}
          </v-btn>
        </v-col>
      </ca-form-row>
    </v-form>

    <template #actions>
      <ca-submit :loading="saving" :disabled="!canSubmit" @click="save">{{ t("update_security_options") }}</ca-submit>
    </template>
  </settings-card>
</template>

<script>
import SettingsCard from "@/views/pages/settings/SettingsCard";
import CaTextField from "@/views/components/form/fields/TextFieldDenseAndFilled";
import CaFormRow from "@/views/components/form/FormRow";
import CaSubmit from "@/views/components/form/actions/Submit";

export default {
  components: { CaSubmit, CaFormRow, CaTextField, SettingsCard },
  data() {
    return {
      formValid: false,
      useSecurityCode: false,
      securityCode: null,
      currentSecurityCode: null,
      securityCodeConfirmation: null,
      passProtection: false,
      saving: false,
      cleaningAllSessions: false,
      oldData: {
        useSecurityCode: null,
        securityCode: null,
        securityCodeConfirmation: null,
        passProtection: null
      },
      errors: {}
    };
  },
  computed: {
    isSecurityCodeSet() {
      return this.user.security.securityCode;
    },
    user() {
      return this.$store.state.auth.userData;
    },
    canSubmit() {
      return (
        this.useSecurityCode !== this.oldData.useSecurityCode ||
        this.securityCode !== this.oldData.securityCode ||
        this.securityCodeConfirmation !== this.oldData.securityCodeConfirmation ||
        this.passProtection !== this.oldData.passProtection
      );
    },
    rules() {
      return {
        securityCodeConfirmation: [
          v => this.securityCodeConfirmed(v) || this.t("errors.security_code_not_match_confirmation")
        ]
      };
    }
  },
  mounted() {
    this.setData();
  },
  methods: {
    setData() {
      this.useSecurityCode = this.oldData.useSecurityCode = this.user.security.useSecurityCode;
      this.securityCode = this.oldData.securityCode = this.user.security.securityCode;
      this.securityCodeConfirmation = this.oldData.securityCodeConfirmation = this.user.security.securityCode;
      this.passProtection = this.oldData.passProtection = this.user.security.passProtection;
    },
    save() {
      this.errors = {};

      if (!this.canSubmit) {
        return;
      }

      if (!this.$refs.form.validate()) {
        return;
      }

      if (!this.formValid) {
        return;
      }

      this.saving = true;

      let data = {
        useSecurityCode: this.useSecurityCode,
        securityCode: this.securityCode,
        passProtection: this.passProtection,
        currentSecurityCode: this.currentSecurityCode
      };

      this.$store.dispatch("app/doSecureAction", {
        callback: () => {
          this.axios
            .post("user/settings/security", data)
            .then(response => {
              this.saving = false;
              this.$store.commit("auth/setUserData", response.data);
              this.setData();
            })
            .catch(error => {
              this.saving = false;
              this.errors = error.response.data.errors;
            });
        },
        onClose: () => {
          this.saving = false;
        },
        mode: "password"
      });
    },
    securityCodeConfirmed(code) {
      return (this.useSecurityCode && code === this.securityCode) || !this.useSecurityCode;
    },
    cleanAllSessions() {
      this.cleaningAllSessions = true;

      this.axios.post("security/clean-all-sessions").then(() => {
        this.cleaningAllSessions = false;
      });
    },
    t(key, params) {
      return this.$t("pages.settings." + key, params);
    }
  }
};
</script>
