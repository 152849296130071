<template>
  <settings-card :title="t('personal_info')" icon="mdi-account-details">
    <v-form class="p-0">
      <ca-form-row>
        <v-col cols="12">
          <ca-text-field v-model="user.email" disabled :label="t('email')" />
        </v-col>
      </ca-form-row>

      <ca-form-row>
        <v-col cols="6">
          <ca-text-field
            v-model="firstName"
            :label="t('first_name')"
            :error-messages="errors.firstName"
            @onEnter="save"
          />
        </v-col>

        <v-col cols="6">
          <ca-text-field v-model="lastName" :label="t('last_name')" :error-messages="errors.lastName" @onEnter="save" />
        </v-col>
      </ca-form-row>

      <ca-form-row>
        <v-col cols="12">
          <ca-text-field
            v-model="contactDetails"
            :label="t('contact_details')"
            :hint="t('contact_details_hint')"
            :error-messages="errors.contactDetails"
            @onEnter="save"
          />
        </v-col>
      </ca-form-row>
    </v-form>

    <template #actions>
      <ca-submit :loading="saving" :disabled="!canSubmit" @click="save">{{ t("update_personal_info") }}</ca-submit>
    </template>
  </settings-card>
</template>

<script>
import SettingsCard from "./SettingsCard";
import CaTextField from "@/views/components/form/fields/TextFieldDenseAndFilled";
import CaFormRow from "@/views/components/form/FormRow";
import CaSubmit from "@/views/components/form/actions/Submit";

export default {
  components: { CaSubmit, CaFormRow, SettingsCard, CaTextField },
  data() {
    return {
      firstName: null,
      lastName: null,
      contactDetails: null,
      oldData: {
        firstName: null,
        lastName: null,
        contactDetails: null
      },
      errors: {},
      saving: false
    };
  },
  computed: {
    canSubmit() {
      return (
        this.oldData.firstName !== this.firstName ||
        this.oldData.lastName !== this.lastName ||
        this.oldData.contactDetails !== this.contactDetails
      );
    },
    user() {
      return this.$store.state.auth.userData;
    }
  },
  mounted() {
    this.setData();
  },
  methods: {
    setData() {
      this.firstName = this.oldData.firstName = this.user.personal_info.first_name;
      this.lastName = this.oldData.lastName = this.user.personal_info.last_name;
      this.contactDetails = this.oldData.contactDetails = this.user.personal_info.contact_details;
    },
    save() {
      if (!this.canSubmit) {
        return;
      }

      this.saving = true;
      this.errors = {};

      this.axios
        .post("user/settings/personal-info", {
          firstName: this.firstName,
          lastName: this.lastName,
          contactDetails: this.contactDetails
        })
        .then(response => {
          this.saving = false;

          this.$store.commit("auth/setUserData", response.data);

          this.setData();
        })
        .catch(error => {
          this.saving = false;

          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    t(key, params) {
      return this.$t("pages.settings." + key, params);
    }
  }
};
</script>
