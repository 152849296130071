<template>
  <settings-card :title="t('change_password')" icon="mdi-form-textbox-password">
    <v-form class="p-0">
      <ca-form-row>
        <v-col>
          <ca-text-field
            v-model="oldPassword"
            type="password"
            :label="t('old_password')"
            :error-messages="errors.oldPassword"
            @onEnter="save"
          />
        </v-col>
      </ca-form-row>

      <ca-form-row>
        <v-col>
          <ca-text-field
            v-model="newPassword"
            type="password"
            :label="t('new_password')"
            :error-messages="errors.newPassword"
            @onEnter="save"
          />
        </v-col>
      </ca-form-row>

      <ca-form-row>
        <v-col>
          <ca-text-field
            v-model="newPasswordConfirm"
            type="password"
            :label="t('new_password_confirm')"
            :error-messages="errors.newPasswordConfirm"
            @onEnter="save"
          />
        </v-col>
      </ca-form-row>
    </v-form>

    <template #actions>
      <ca-submit :loading="saving" :disabled="!canSubmit" @click="save">{{ t("change_password_submit") }}</ca-submit>
    </template>
  </settings-card>
</template>

<script>
import SettingsCard from "./SettingsCard";
import CaFormRow from "@/views/components/form/FormRow";
import CaSubmit from "@/views/components/form/actions/Submit";
import CaTextField from "@/views/components/form/fields/TextFieldDenseAndFilled";

export default {
  components: { CaSubmit, CaFormRow, SettingsCard, CaTextField },
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
      errors: {},
      saving: false
    };
  },
  computed: {
    canSubmit() {
      return this.oldPassword !== "" && this.newPassword !== "" && this.newPasswordConfirm !== "";
    }
  },
  methods: {
    save() {
      if (!this.canSubmit) {
        return;
      }

      if (this.newPassword !== this.newPasswordConfirm) {
        this.errors = {
          newPasswordConfirm: [this.t("errors.password_not_match_confirmation")]
        };
        return;
      }

      this.saving = true;
      this.errors = {};

      this.axios
        .post("auth/change-password", {
          oldPassword: this.oldPassword,
          newPassword: this.newPassword
        })
        .then(() => {
          this.saving = false;
          this.oldPassword = "";
          this.newPassword = "";
          this.newPasswordConfirm = "";

          this.$store.dispatch("app/showMainAlert", {
            type: "warning",
            message: this.t("password_changing_notification_sent"),
            dismissible: true
          });
        })
        .catch(error => {
          this.saving = false;

          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          } else {
            alert("Fail changin password");
          }
        });
    },
    t(key, params) {
      return this.$t("pages.settings." + key, params);
    }
  }
};
</script>
