<template>
  <div>
    <v-row justify="center">
      <v-col cols="12">
        <ca-page-header :title="t('page_header')" icon="mdi-account-cog" color="info">
          <i18n path="pages.settings.page_subtitle" tag="p">
            <template #url>
              <router-link :to="{ name: 'payouts' }">{{ t("payouts") }}</router-link>
            </template>

            <template #br>
              <br />
            </template>
          </i18n>
        </ca-page-header>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" lg="6">
        <personal-info />
      </v-col>
      <v-col cols="12" lg="6">
        <change-password />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <security-options />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PersonalInfo from "./PersonalInfo";
import ChangePassword from "@/views/pages/settings/ChangePassword";
import SecurityOptions from "@/views/pages/settings/SecurityOptions";
import CaPageHeader from "../../components/PageHeader";

export default {
  components: {
    CaPageHeader,
    SecurityOptions,
    ChangePassword,
    PersonalInfo
  },
  metaInfo() {
    return {
      title: this.$t("titles.settings")
    };
  },
  methods: {
    t(key, params) {
      return this.$t("pages.settings." + key, params);
    }
  }
};
</script>
