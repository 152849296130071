<template>
  <v-row style="margin-bottom: -25px !important;">
    <slot></slot>
  </v-row>
</template>

<script>
export default {
  name: "CaFormRow",
  components: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  destroyed() {},
  methods: {}
};
</script>
